.user-container {
  display: flex;
  flex-direction: row;
  height: 50px;
  padding-left: 10px;
}

.site-layout-content > .anticon-plus {
  float: right;
  /* margin-top: 10px; */
}

p {
  width: 15px;
}
