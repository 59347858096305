.vital-main-section {
  display: grid;
  .ant-tabs-nav-list {
    color: black;
    background: none;
    border-radius: 33px;
  }
  .ant-tabs-tab-active {
    background: #7ae8e2;
    color: white;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-picker-range {
    border-radius: 12px;
    background: #08aca4;
    padding: 2px;
  }
  .ant-picker-input {
    border-radius: 12px;
    background: #08aca4;
    padding: 6px;
  }

  .ant-picker-input > input {
    color: white;
  }
  .line-chart-col > div > div > div > canvas {
    height: 400px !important;
  }

  // .line-chart-col > canvas {
  //   height: 800px !important;
  // }
  .ant-picker-suffix {
    color: white;
  }
  .ant-picker-separator {
    color: white;
    vertical-align: super;
  }
}
