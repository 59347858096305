.anticon-close-circle > svg {
  width: 2em;
  height: 2em;
}
.anticon-check-circle > svg {
  width: 2em;
  height: 2em;
}
.anticon-video-camera > svg {
  width: 2em;
  height: 2em;
}
/* .anticon-eye > svg {
  width: 2em;
  height: 2em;
} */
