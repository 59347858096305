.ant-layout-header {
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0;
}

.ant-layout.ant-layout-has-sider {
  margin-top: 64px;
}

.ant-layout-sider {
  position: fixed;
  width: 15%;
  max-width: 250px;
  z-index: 1;
  height: 100vh;
  left: 0;
}

.ant-layout-content {
  width: 85% !important;
  margin-left: 15%;
}
