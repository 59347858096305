.homepage {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
}

.top-right {
  position: absolute;
  right: 10px;
  top: 18px;
}

.m-b-md {
  margin-bottom: 30px;
}

.title {
  font-size: 50px;
  text-align: center;
  color: #636b6f;
  font-weight: 200;
}

.links {
  color: #636b6f;
  padding: 0 25px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.1rem;
  text-decoration: none;
  text-transform: uppercase;
}
