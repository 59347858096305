.medications-container {
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 100%;
  padding-left: 10px;
}

.medications-container > .ant-btn {
  margin-left: auto;
  margin-top: 10px;
}
