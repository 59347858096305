.user-container {
  display: flex;
  flex-direction: row;
  height: 50px;
  padding-left: 10px;
  margin-top: 10px;
}

.sub-heading {
  display: flex;
  flex-direction: row;
}

.user-container > .ant-select {
  margin-left: auto;
  /* align-self: flex-end; */
  margin-top: 10px;
}

.sub-heading > .ant-btn {
  margin-left: auto;
  /* margin-top: 10px; */
}
