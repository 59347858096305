.option {
  padding: 10px 15px;
  cursor: pointer;
}

.medicalreports-container {
  display: flex;
  flex-direction: row;
  height: 50px;
  padding-left: 10px;
}

.medicalreports-container > .ant-btn {
  margin-left: auto;
  margin-top: 10px;
}

.card-Item {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-wrap: wrap;
}

.form {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  position: absolute;
  bottom: 0px;
  right: 65px;
}
/* background-color: #e9bcb7; */
/* background-image: linear-gradient(315deg, #e9bcb7 0%, #29524a 74%); */
