.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.justify-content-center {
  justify-content: center !important;
}

.login-container {
  max-width: 960px;
  margin-top: 64px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}

.login-container > .ant-layout-content {
  min-height: 635px;
}

.row {
  display: flex;
  /* flex-wrap: wrap; */
  margin-right: -15px;
  margin-left: -15px;
  padding-top: 5%;
}

.col-md-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.card-body {
  flex: 2 2 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}
