.site-layout-content {
  min-height: 650px;
  padding: 4px;
  background: #fff;
  width: 1240px;
}

/* .ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
} */

.page-title {
  /* display: inline-block; */
  float: left;
  padding: 10px 0px;
}

.page-title > h1 {
  color: #697882;
  font-size: 22px;
  font-weight: 400;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: row;
  height: 50px;
  padding-left: 0;
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
